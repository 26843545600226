@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide the scrollbar in supported browsers */
.scrollbar-hide::-webkit-scrollbar {
  width: 0.5rem; /* Set the width of the scrollbar as needed */
  display: none; /* Hide the scrollbar in Webkit browsers (Chrome, Safari) */
}

.scrollbar-hide {
  scrollbar-width: none; /* Hide the scrollbar in Firefox */
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the scrollbar thumb transparent in Webkit browsers */
}

/* .visa-bg::before {
  background-image: url('./visa-king.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
